<template>
    <div class="modal" id="appointmentMemotModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div
          class="modal-content"
          style="overflow: scroll; height: 250px; padding: 10px"
        >
          <h4>Not Ekle</h4>
          <hr class="m-1" />
          <div class="message" v-if="messageShow">{{ message }}</div>
          <div class="form-group">
            <label>Not:*</label>
            <textarea
              type="text"
              v-model="description"
              class="form-control"
              placeholder="Açıklama Girin"
            ></textarea>
          </div>
          <button class="btn btn-primary" @click="setPayment">Not Ekle</button>
          <small>* ile işaretli alanların doldurulması zorunludur.</small>
        </div>
      </div>
    </div>
  </template>
    <style scoped>
  .text {
    text-align: right;
    font-size: 20px;
  }
  hr {
    border-top: 1px dashed #ccc;
  }
  .message {
    padding: 4px;
    backdrop-filter: blur(2px);
    border-radius: 5px;
    text-align: center;
    position: absolute;
    width: 100%;
    margin-left: -10px;
    margin-top: -5px;
  }
  </style>
      <script>
  import Swal from "sweetalert2";
  export default {
    props: ["preAppointmentId"],
    created() {
      this.selectedId=this.preAppointmentId
    },
    data() {
      return {
        selectedId: 0,
        processType: 0,
        price: 0,
        description: "",
        messageShow: false,
        message: "",
      };
    },
    watch: {
      preAppointmentId: function () {
        this.selectedId = this.preAppointmentId;
      },
    },
    methods: {
      messageSet(message) {
        this.messageShow = true;
        this.message = message;
        setTimeout(() => {
          this.messageShow = false;
        }, 1500);
      },
      setPayment() {
        if (!this.selectedId || this.selectedId == 0) {
          this.messageSet("Randevu Geçersiz");
        } else if (this.description == null || this.description == "") {
          this.messageSet("Açıklaması girilmedi");
        } else {
          let info = {
            preAppointmentId: this.selectedId,
            description: this.description,
          };
          this.$store.dispatch("myPreAppointmentMemoSet", info).then((value) => {
            if (value.type == "success") {
              value.status=true;
              value.preAppointmentId=this.preAppointmentId;
              this.$emit("response", value);
              $("#appointmentMemotModal").modal("hide");
              Swal.fire(
                "Not Eklendi!",
                "Randevuya ait not ekleme işlemi başarılı",
                "success"
              );
  
            } else {
              if (value.message == "ERRxPNI") {
                //Ücret Eklenemedi
                Swal.fire("Hata!", "Not ekleme sırasında hata oluştu", "error");
              }
            }
          });
        }
      },
    },
    components: {},
    mounted() {
    },
  };
  </script>