<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card">
      <div class="card-header">
        <h2 class="card-title">Randevu Bilgileri</h2>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="item-dc col-4">
            <div class="row border-dotted mb-2">
              <div class="col-lg-6 col-md-12">
                <b>Alan:</b><br />
                {{ result.fullname }}
              </div>
              <div class="col-lg-6 col-md-12">
                <b>Tarih:</b><br />
                {{ result.date }}
              </div>

              <div class="col-lg-6 col-md-12" v-if="result.user != null">
                <b>Oluşturan:</b><br />
                {{ result.user.fullname }}
              </div>

              <div class="col-lg-6 col-md-12">
                <b>Durumu:</b><br />
                <template v-if="result.status == 4">
                  <div class="circle pulse red"></div>
                  <span>İptal</span>
                </template>
                <template v-if="result.status == 3">
                  <div class="circle pulse purple"></div>
                  <span>Gelmedi</span>
                </template>
                <template v-if="result.status == 2">
                  <div class="circle pulse orange"></div>
                  <span>Başlamadı</span>
                </template>
                <template v-if="result.status == 1">
                  <div class="circle pulse green"></div>
                  <span>Başladı</span>
                </template>
                <template v-if="result.status == 0">
                  <div class="circle pulse grey"></div>
                  <span>Yeni Randevu</span>
                </template>
              </div>
            </div>
            <div class="col-12">
              <h5 class="bold">Mesajlar</h5>
              <div class="row">
                <div class="col">
                  <b>Oluşturan:</b>
                </div>
                <div class="col">
                  <b>Tarih:</b>
                </div>
                <div class="col">
                  <b>Not:</b>
                </div>
                <div class="col">
                  <b>İşlem</b>
                </div>
              </div>
              <div class="payment-scroll">
                <div v-for="(message, a) in result.memos" :key="a" class="row">
                  <div class="col">
                    <small>{{ message.user.fullname }}</small>
                  </div>
                  <div class="col">
                    <small>{{ message.created_at }}</small>
                  </div>
                  <div class="col">
                    <small>{{ message.log }}</small>
                  </div>
                  <div class="col">
                    <button class="btn btn-sm btn-danger" @click="removeLog(message.id)">
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                  <hr class="m-1" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-8 m-0 p-0">
            <div class="item-dc col-12">
              <div class="btn-group" style="display: flex; justify-content: flex-end" role="group"
                aria-label="Basic example">
                <button type="button" class="btn btn-primary" @click="preAppointmentPaymentProcess(id)">
                  Ücret İşle
                </button>
                <button class="btn btn-primary" type="button" :id="'statusMenu'" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  Durum Değiştir
                </button>
                <ul class="dropdown-menu" aria-labelledby="statusMenu">
                  <li>
                    <a class="dropdown-item" @click="changeStatus(id, 0)">
                      <div style="
                        
                          width: 10px;
                          height: 10px;
                          border-radius: 10px;
                          background: grey;
                          float: left;
                          margin-top: 4px;
                          margin-right: 5px;
                        "></div>
                      <small style="float: left">Yeni Randevu</small>
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" @click="changeStatus(id, 2)">
                      <div style="
                          width: 10px;
                          height: 10px;
                          border-radius: 10px;
                          background: orange;
                          float: left;
                          margin-top: 4px;
                          margin-right: 5px;
                        "></div>
                      <small style="float: left">Başlamadı</small>
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" @click="changeStatus(id, 1)">
                      <div style="
                          width: 10px;
                          height: 10px;
                          border-radius: 10px;
                          background: green;
                          float: left;
                          margin-top: 4px;
                          margin-right: 5px;
                        "></div>
                      <small style="float: left">Başladı</small>
                    </a>
                  </li>

                  <li>
                    <a class="dropdown-item" @click="changeStatus(id, 3)">
                      <div style="
                          width: 10px;
                          height: 10px;
                          border-radius: 10px;
                          background: purple;
                          float: left;
                          margin-top: 4px;
                          margin-right: 5px;
                        "></div>
                      <small style="float: left">Gelmedi</small>
                    </a>
                  </li>

                  <li>
                    <a class="dropdown-item" @click="changeStatus(id, 4)">
                      <div style="
                          width: 10px;
                          height: 10px;
                          border-radius: 10px;
                          background: red;
                          float: left;
                          margin-top: 4px;
                          margin-right: 5px;
                        "></div>
                      <small style="float: left">İptal</small>
                    </a>
                  </li>
                </ul>
                <button type="button" class="btn btn-primary" @click="enterMemo(result.id)">
                  Not Gir
                </button>
                <a target="_self" :href="'/profile/appointments/details/appointment-plan?id=' + id" type="button"
                  class="btn btn-primary">
                  Randevuyu Planla
                </a>
                <a @click="removeAppointmentProcess(result.id)" type="button"
                  class="btn btn-danger remove">
                  <i class="fa fa-trash"></i>
                </a>
                
              </div>
            </div>
            <div class="item-dc col-12">
              <b>Ücret İşlemleri:</b>
              <div class="payment-scroll">
                <div v-for="(payment, a) in result.payments" :key="a" class="col-12 border-dotted">
                  <div class="center bold">{{ payment.created_at }}</div>
                  <div class="price-text">
                    <div v-if="payment.type == 0" class="positive">
                      <span>{{ payment.price }}</span>
                      <span>TL</span>
                    </div>
                    <div v-if="payment.type == 1" class="negative">
                      <span>{{ payment.price }}</span>
                      <span>TL</span>
                    </div>
                  </div>
                  <small><b>İşlemi Yapan:</b>{{ payment.user.fullname }}</small><br />
                  <small><b>Açıklama:</b>{{ payment.description }}</small>
                </div>
              </div>
              <div class="row p-4">
                <div class="col-12 price-text">
                  TOPLAM: {{ result.total }}TL
                </div>
                <div class="col-12 price-text">
                  ALINAN: {{ result.received }}TL
                </div>
                <div class="col-12 price-text">
                  KALAN: {{ result.remainder }}TL
                </div>
                <div class="col-12 price-text">
                  KOMİSYON: {{ result.collection_amount }}TL
                </div>
              </div>
            </div>
          </div>
          <div class="item-dc col-12 payment-scroll">
            <div v-for="(log, a) in result.logs" :key="a" class="row">
              <div class="col">
                <b>Oluşturan:</b><br />
                {{ log.user.fullname }}
              </div>
              <div class="col">
                <b>Oluşturulma Tarihi:</b><br />
                {{ log.created_at }}
              </div>
              <div class="col">
                <b>Log:</b><br />
                {{ log.log }}
              </div>
            </div>
            <AppointmentPaymentProcess :preAppointmentId="id" @response="paymentReturn($event)" />
            <AppointmentMemoProcess :preAppointmentId="id" @response="memoReturn($event)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }

  100% {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }
}

.payment-scroll {
  max-height: 350px;
  overflow: scroll;
}

.dropdown-item {
  height: 20px;
  cursor: pointer;
}
.remove{
    background-color: #9b5575;
    border: none;

}
.circle {
  margin-top: 6px;
  margin-right: 5px;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  float: left;
}

.circle.red {
  background: red;
}

.circle.green {
  background: green;
}

.circle.grey {
  background: grey;
}

.circle.purple {
  background: purple;
}

.circle.orange {
  background: orange;
}

.item-dc {
  border-radius: 10px;
  background: #f6f6f6;
  padding: 10px;
  border: 5px solid white;
}

.border-dotted {
  border-bottom: 1px dashed #ccc;
}

.center {
  text-align: center;
}

.price-text {
  text-align: right;
  font-size: 20px;
  font-weight: bold;
  color: rgb(103 103 103);
  font-weight: 500;
  font-family: "Maven Pro";
}

.positive {
  width: 40%;
  float: right;
  color: red;
}

.negative {
  width: 40%;
  float: right;
  color: blue;
}

.bold {
  font-weight: bolder;
}
</style>
<script>import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import AppointmentPaymentProcess from "./Components/AppointmentPaymentProcess.vue";
import AppointmentMemoProcess from "./Components/AppointmentMemoProcess.vue";
export default {
  name: "PreAppointmentDetail",
  components: { AppointmentPaymentProcess, AppointmentMemoProcess },
  created() {
    document.title = "Randevu Önizleme";

    if (this.$route.query.id && this.$route.query.id != null) {
      this.id = this.$route.query.id;
    }

    this.getPreAppointment();
  },
  data() {
    return {
      result: [],
      id: 0,
    };
  },
  methods: {
    removeAppointmentProcess(id) {
      Swal.fire({
        title: "Randevu Silinecek!",
        text: "Bu işlemi gerçekleştirmek istediğine emin misin?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sil!",
        cancelButtonText: 'İptal'
      }).then((result) => {
        if (result.isConfirmed) {
          //Silme işlemi onaylandı, sunucuya bilgileri gönder
          let info = {
            id: id,
          };
          this.$store.dispatch("myPreAppointmentRemove", info).then((value) => {
            if (value.type == 'error') {
              Swal.fire({
                title: "Hata!",
                text: value.message,
                icon: "error"
              });
            } else {
              Swal.fire({
                title: "Silindi!",
                text: value.message,
                icon: "success"
              });
            }

          });

        }
      });
    },
    changeStatus(id, status) {
      let info = {
        id: id,
        status: status,
      };
      this.$store.dispatch("myPreAppointmentChange", info).then((value) => {
        this.result.status = status;
        this.logsGet();
      });
    },
    paymentReturn: function (e) {
      let detail = e.payment_detail;
      this.result.total = detail.total;
      this.result.remainder = detail.remainder;
      this.result.received = detail.received;
      this.result.collection_amount = detail.collection_amount;
      this.result.payment_status = detail.payment_status;
    },
    preAppointmentPaymentProcess(id) {
      this.id = id;
      $("#appointmentPaymentModal").modal("show");
    },
    enterMemo(id) {
      this.id = id;
      $("#appointmentMemotModal").modal("show");
    },
    memoReturn(e) {
      //Notları güncelle
      let info = {
        id: this.id,
        types: "MEMO",
      };
      this.$store.dispatch("myPreAppointmentLogGet", info).then((value) => {
        this.result.memos = value.logs;
      });
    },
    removeLog(id) {
      //LOG SİLER
      let info = {
        id: id,
        types: "MEMO",
      };
      this.$store.dispatch("myPreAppointmentLogRemove", info).then((value) => {
        let info = {
          id: this.id,
          types: "MEMO",
        };
        this.$store.dispatch("myPreAppointmentLogGet", info).then((value) => {
          this.result.memos = value.logs;
          this.logsGet();
        });
      });
    },
    logsGet() {
      let info = {
        id: this.id,
        types: "LOG",
      };
      this.$store.dispatch("myPreAppointmentLogGet", info).then((value) => {
        this.result.logs = value.logs;
      });
    },
    getPreAppointment() {
      this.result = [];
      let info;

      info = {
        id: this.id,
      };

      this.$store.dispatch("myPreAppointmentGet", info).then((value) => {
        this.result = value.result;
      });
    },
  },
  watch: {
    "$route.query.id": function () {
      if (this.$route.query.id && this.$route.query.id != null) {
        this.currentpage = this.$route.query.page;
      }
      this.getPreAppointment();
    },
  },
};
</script>